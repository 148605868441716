import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private readonly overlayToggledSubject = new BehaviorSubject<boolean>(false);
  overlayToggled$ = this.overlayToggledSubject.asObservable();

  private readonly overlayClickedSubject = new Subject<void>();
  overlayClicked$ = this.overlayClickedSubject.asObservable();

  get isOverlayShown() {
    return this.overlayToggledSubject.value;
  }

  showOverlay() {
    this.overlayToggledSubject.next(true);
  }

  hideOverlay() {
    this.overlayToggledSubject.next(false);
  }

  toggleOverlay() {
    if (this.isOverlayShown) {
      this.hideOverlay();
    } else {
      this.showOverlay();
    }
  }

  onOverlayClicked() {
    this.overlayClickedSubject.next();
  }
}
